
import { httpGet, httpPost } from '@/api';
import { computed, reactive, ref, toRefs } from 'vue';
import { erpConfirm, errorMessage, getRouterParams, successMessage, exportFile, showFiles } from '@/utils/publicMethods';
import router from '@/router';
import BaseInfo from './components/BaseInfo/index.vue';
import useVisible from '@/hooks/useVisible';
import WoollyDetail from './components/WoollyDetail/WoollyDetail.vue';
import ViewOffer from './components/ViewOffer/index.vue';
import ViewCounterOffer from './components/ViewCounterOffer/index.vue';
import TableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import OfferForm from './components/OfferForm/OfferForm.vue';
export default {
  name: 'OfferDetails',
  components: {
    BaseInfo,
    WoollyDetail,
    ViewOffer,
    ViewCounterOffer,
    OfferForm,
    TableFileContent,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id, type: paramType, backFrom } = getRouterParams('OfferDetails');
    console.log('', getRouterParams('OfferDetails'));
    // 当前列表状态
    const type = ref(paramType as number);
    const offerTableData = ref([]);
    const customData = reactive({
      detailsData: {},
      resultData: {},
      tradeCurrency: '',
      tableData: [],
      colNames: ['weightPerContainer', 'transformedContainerQuantity', 'shippingDate', 'containerQuantity'],
      colArrays: {},
      woollyDialog: {
        data: {},
        type: 0,
      },
    });
    const load = ref(true);
    /** Form展示 */
    const { visible, showVisible } = useVisible();

    const leftTitleNames = reactive({
      viewOfferTitle: '已报盘客户',
      viewCounterOfferTitle: '还盘记录',
    });
    // 客户框架合同大于1才显示
    const frameWorkShow = ref(false);
    // 是否能编辑
    const isHidden = computed(() => {
      return type.value > 1;
    });
    const clickTag = (row, type, index) => {
      // 标签
      if (type === 1) {
        window.open(row.labelUrl, '_blank');
      } else if (type === 2) {
        // 规格说明书
        let { name, url } = row.specificationAttachmentDtos[0];
        showFiles(name, url);
      } else {
        // 产品图片
        let { name, url } = row.productPictureAttachmentDtos[0];
        showFiles(name, url);
      }
    };
    const getPartDetails = async (row) => {
      const res = await httpGet(`/malicrm/offer/noAuth/offerProductPartsDetail/${row.id}`);
      if (res.code === 200) {
        const data = {
          partsName: row.nameCn,
          productPartsVos: res.result,
        };
        customData.woollyDialog.data = data;
        customData.woollyDialog.type = 3;
        showVisible();
      } else {
        errorMessage(res.message);
      }
    };
    const objectSpanMethod = ({ column, rowIndex, columnIndex }) => {
      if (columnIndex === 4 || columnIndex === 5 || columnIndex === 7) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (customData.colNames && customData.colNames.length > 0) {
          customData.colNames.forEach((v) => {
            if (v === prop) {
              const _row = customData.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }
        return params;
      }
    };
    const setColArrays = (props) => {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      customData.tableData.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            customData.colArrays[item] = [];
            customData.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (v[prop] === customData.tableData[i - 1][prop] && (v as any).containerSeq === (customData.tableData[i - 1] as any).containerSeq) {
              // 必须是同柜的
              customData.colArrays[prop][dot[prop]] += 1;
              customData.colArrays[prop].push(0);
            } else {
              customData.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    };
    // 返回操作
    const back = () => {
      if (backFrom) {
        router.push(backFrom);
      } else {
        router.push('/offer/offerList');
      }
    };
    // 初始化数据
    const initData = async () => {
      load.value = false;
      const res = await httpGet(`/malicrm/offer/noAuth/offerDetail/${id}`);
      if (res.code === 200) {
        offerTableData.value = res.result.productVoList;
        customData.resultData = JSON.parse(JSON.stringify(res.result)); // 保留为格式化数据
        customData.detailsData = res.result;
        const productData = Object.assign([], res.result.productVoList);
        customData.tradeCurrency = res.result.tradeCurrency;
        productData.forEach((v) => {
          for (const key in v) {
            if (v[key] === null || v[key] === '') {
              v[key] = '-';
            }
          }
        });
        customData.tableData = Object.freeze(productData);
        setColArrays(customData.colNames);
        load.value = true;
      } else {
        if (res.code === 9999100) {
          back();
        } else {
          errorMessage(res.message);
        }
      }
    };
    const exportOrderElement = (type) => {
      const title = type === 2 ? '确定需要下载报盘的excel文件吗？' : '确定需要下载还盘汇总的excel文件吗？';
      const url = type === 2 ? '/malicrm/feedback/exportOffer' : '/malicrm/feedback/exportOfferFeedback';
      erpConfirm(title).then(async () => {
        const params = {
          id: (customData.detailsData as any).id,
        };
        exportFile({
          type: 'POST',
          url,
          data: params,
        });
      });
    };
    const deleteclues = () => {
      erpConfirm('确定要删除当前报盘吗？').then(async () => {
        const params = {
          id: (customData.detailsData as any).id,
          version: (customData.detailsData as any).version,
          status: (customData.detailsData as any).status,
        };
        const res = await httpPost('/malicrm/offer/deleteOffer', params);
        if (res.code === 200) {
          successMessage('已删除报盘');
          router.push('/offer/offerList');
        } else {
          errorMessage(res.message);
        }
      });
    };
    const closeVisible = (status) => {
      visible.value = false;
      if (status) {
        initData();
      }
    };
    const editingclues = () => {
      visible.value = true;
      customData.woollyDialog.type = 2;
    };
    // 设置标题方法
    const getViewOfferTitle = (str) => {
      leftTitleNames.viewOfferTitle = str;
    };
    const getViewCounterOfferTitle = (str) => {
      leftTitleNames.viewCounterOfferTitle = str;
    };
    initData();
    setTimeout(() => {
      console.log('offerTableData', offerTableData);
      console.log('offerTableData', offerTableData.value);
    }, 0);
    return {
      load,
      id,
      type,
      isHidden,
      ...toRefs(customData),
      back,
      ...toRefs(leftTitleNames),
      getViewOfferTitle,
      getViewCounterOfferTitle,
      frameWorkShow,
      visible,
      showVisible,
      closeVisible,
      setColArrays,
      objectSpanMethod,
      getPartDetails,
      clickTag,
      exportOrderElement,
      deleteclues,
      editingclues,
      offerTableData,
    };
  },
};
