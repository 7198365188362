<template>
  <CrmFormLayout :title="'记录客户'" @close="cancel">
    <h2 style="font-size: 16px; color: #333; margin-bottom: 11px">
      记录已发送本报盘的客户
    </h2>
    <div class="qutoted-table">
      <div class="qutoted__customer-flex">
        <div
          class="qutoted__customer-title"
          style="border-top: 1px solid #e3e4e5"
        >
          客户
        </div>
        <div
          class="qutoted__customer-plus"
          style="border-top: 1px solid #e3e4e5"
        >
          <span class="el-icon-circle-plus-outline" @click="add"></span>
        </div>
        <div></div>
      </div>
      <div
        class="qutoted__customer-flex customer-body"
        v-for="(item, index) in data"
        :key="index"
      >
        <div
          class="qutoted__customer-input"
          :class="index === 0 ? 'border-no' : ''"
        >
          <CutomerSelect
            :value="item.customerId"
            @input="getValue($event, item, index)"
          ></CutomerSelect>
        </div>
        <div
          class="qutoted__customer-remove"
          :class="data.length == index + 1 ? 'border' : ''"
        >
          <span
            class="el-icon-remove-outline"
            @click="remove(item, index)"
          ></span>
        </div>
        <div class="flex-mes">
          <span>{{ item.message }}</span>
        </div>
      </div>
    </div>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submit">保存</button>
      <button class="crm-form__button cancel" @click="cancel(false)">
        关闭
      </button>
    </div>
  </CrmFormLayout>
</template>

<script>
import CutomerSelect from './CutomerSelect';
import { erpConfirm } from '@/utils/publicMethods';
export default {
  components: {
    CutomerSelect,
  },
  props: {
    offerId: {
      type: [String, Number],
      default: '',
    },
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看 查看没有操作按钮
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      data: [
        {
          customerId: null,
          message: null,
        },
      ],
    };
  },
  methods: {
    empty(val) {
      if (val !== null && val !== '') {
        return val;
      } else {
        return '-';
      }
    },
    add() {
      const params = {
        customerId: null,
        message: null,
      };
      this.data.push(params);
    },
    remove(item, index) {
      if (item.customerId === null || item.customerId === '') {
        this.data.splice(index, 1);
        this.handleRepeatFn();
      } else {
        erpConfirm('确定要删除吗？').then(() => {
          this.data.splice(index, 1);
          this.handleRepeatFn();
        });
      }
    },
    getValue(value, data) {
      const item = data;
      item.customerId = value;
      this.handleRepeatFn(); // 处理相同报盘记录
    },
    // 处理相同报盘记录
    handleRepeatFn() {
      const preArr = this.data.map((v) => {
        if (v.customerId !== null) {
          return v.customerId;
        }
      });
      // 重复集合
      let reArr = [];
      reArr = [...new Set(preArr)];
      reArr.forEach((item) => {
        const firstIndex = preArr.indexOf(item);
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].customerId === item) {
            if (i === firstIndex) {
              this.data[i].message = null;
            } else {
              this.data[i].message = '该客户已有报盘记录';
            }
          }
        }
      });
    },
    submit() {
      // 提交操作
      const customer = this.data.filter((v) => v.customerId !== null);
      this.data = customer;
      if (customer.length > 0) {
        const messageData = this.data.filter((v) => v.message !== null);
        if (messageData.length > 0) {
          return false;
        } else {
          this.submitForm();
        }
      } else {
        this.errorTip('请输入客户');
      }
    },
    submitForm() {
      // 提交表单
      const params = {
        customerIds: this.data
          .filter((v) => v.customerId !== null)
          .map((v) => v.customerId),
        offerId: this.offerId,
      };
      this.ajax({
        type: 'POST',
        url: '/malicrm/offer/customerMarkOffer',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            const messageData = res.result.filter((v) => v.message !== null);
            if (messageData.length > 0) {
              res.result.forEach((v, i) => {
                this.data[i].message = v.message;
              });
            } else {
              this.successTip('标记成功', 2000);
              this.$emit('close', true);
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    cancel() {
      // 取消操作
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped lang="scss">
.flex-mes {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #ea3b3b;
}
.qutoted-table {
  margin-top: 10px;
}
.qutoted__customer-title {
  width: 340px;
  height: 50px;
  border: 1px solid #e3e4e5;
  border-top: none;
  background: #fff;
  line-height: 50px;
  text-align: center;
}
.qutoted__customer-plus {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e3e4e5;
  border-top: none;
  border-left: none;
}
.qutoted__customer-remove {
  height: 44px;
  width: 50px;
  line-height: 44px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e3e4e5;
  border-left: none;
  border-top: none;
}

.qutoted__customer-flex {
  display: flex;
}
.qutoted__customer-input {
  width: 340px;
  height: 44px;
  border: 1px solid #e3e4e5;
  border-bottom: none;
  background: #fff;
  padding: 10px 6px;
  text-align: center;
  display: flex;
  align-items: center;
  &.border-no {
    border-top: none;
  }
}
.customer-body .qutoted__customer-input {
  border-top: none;
  border-bottom: 1px solid #e3e4e5;
}
</style>
