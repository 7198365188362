
// 线索基础模块信息
import { defineComponent } from 'vue';
import useFilter from '@/hooks/useFilter';
import { showFiles } from '@/utils/publicMethods';
export default defineComponent({
  name: 'clueClientInfo',
  props: ['data', 'tableData'],
  setup() {
    const { empty } = useFilter();
    const statusClass = (status) => {
      const statusClass = [
        '',
        'warning-status',
        'success-status',
        'error-status',
      ];
      return statusClass[Number(status)];
    };
    return { empty, showFiles, statusClass };
  },
});
