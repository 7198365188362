<template>
  <CrmFormLayout :title="formType === 1 ? '新建还盘' : '编辑还盘'" @close="close">
    <el-form :model="formData" label-position="top" ref="form" :rules="rules">
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="大类" prop="category">
              <el-input class="mali-full__input" v-model="formData.categoryDesc" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家">
              <el-input class="mali-full__input" v-model="formData.countryDesc" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂号" prop="plantNo">
              <el-input class="mali-full__input" v-model="formData.plantNo" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商" prop="supplierId">
              <el-input class="mali-full__input" v-model="formData.supplierName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Incoterm" prop="incoterm">
              <el-input v-model="formData.incotermDesc" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="付款条件" prop="supplierPaymentRatio">
              <el-input v-model="formData.supplierPaymentRatio" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交易币种" prop="tradeCurrency">
              <el-input v-model="formData.tradeCurrencyDesc" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当日汇率" prop="exchangeRate">
              <el-input v-model="formData.exchangeRate" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户" prop="customerId">
              <el-select
                v-model="formData.customerId"
                :filter-method="getCustomerList"
                filterable
                remote
                clearable
                @clear="getCustomerList('')"
                :popper-append-to-body="false"
                style="width: 100%"
                @blur="changeCustomer"
                placeholder=" "
              >
                <el-option v-for="(item, index) in options" :key="index" :label="item.customerName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="还盘状态" prop="status" required>
              <el-select v-model="formData.status" placeholder style="width: 100%" v-if="formType !== 1">
                <el-option label="跟进中" :value="1"></el-option>
                <el-option label="失败" :value="2" v-if="formType === 2"></el-option>
              </el-select>
              <el-input v-model="statusName" :disabled="true" v-else></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="目的港" prop="portArrival">
              <el-select
                v-model="formData.portArrival"
                placeholder="请选择目的港"
                class="mali-full__input"
                filterable
                remote
                clearable
                @clear="getportArrList('')"
                :filter-method="getportArrList"
              >
                <el-option v-for="item in portArrList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <ViewCounterOfferTable
        class="mt-20"
        :tradeCurrency="formData.tradeCurrency"
        :offer-data="offerData"
        ref="offerTable"
        v-if="status"
      ></ViewCounterOfferTable>

      <div class="mali-edit__form label-con" style="margin-top: 20px">
        <el-form-item label="还盘备注" prop="remark">
          <el-input v-model="formData.remark" :maxlength="1000" clearable class="mali-full__input" type="textarea" resize="none"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="submit">保存</button>
      <button class="crm-form__button cancel" @click="close(false)">取消</button>
    </div>
  </CrmFormLayout>
</template>

<script>
import { errorMessage } from '@/utils/publicMethods';
import ViewCounterOfferTable from './ViewCounterOfferTable.vue';
export default {
  components: { ViewCounterOfferTable },
  props: {
    detailsData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 修改
      type: [String, Number],
      default: '',
    },
    listType: {
      type: [String, Number],
      default: '',
    },
    eventId: {
      // 任务类型(来源)
      type: [String, Number],
      default: 1,
    },
    offerId: {
      // 报盘id
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      statusName: '跟进中',
      status: false,
      offerData: [],
      portArrList: [],
      formData: {
        category: '',
        categoryDesc: '',
        country: '',
        countryDesc: '',
        customerId: null,
        customerName: '',
        exchangeRate: 0,
        feedbackProductList: [],
        incoterm: '',
        incotermDesc: '',
        plantNo: '',
        portArrival: '',
        remark: '',
        status: 1,
        statusDesc: '',
        supplierId: 0,
        supplierName: 'string',
        supplierPaymentRatio: '',
        tradeCurrency: '',
        tradeCurrencyDesc: '',
      },
      rules: {
        customerId: [
          {
            required: true,
            message: '请选择客户',
            trigger: 'change',
          },
        ],
      },
      Options: {
        // goods_category_label: null,
        // trade_currency: null
      },
      options: [],
    };
  },
  methods: {
    // 获取目的港列表
    initPortArrList() {
      if (this.portArrList && this.portArrList.length <= 0) {
        this.getportArrList('');
      }
    },
    getportArrList(name) {
      // that.portArrList = [];
      this.ajax({
        type: 'POST',
        url: 'malicrm/port/queryByName',
        data: {
          portName: name,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.portArrList = res.result;
            } else {
              this.portArrList = [];
            }
          }
        },
      });
    },
    changeCustomer() {
      if (this.options.length <= 0) {
        this.getCustomerList('');
      }
    },
    changePortArrival(e) {
      let value = e.target.value;
      value = value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除中文以外的字符
      this.formData.portArrival = value;
    },
    getFeedBackData(id) {
      // 获取还盘详情id
      this.ajax({
        type: 'GET',
        url: `/malicrm/feedback/getOfferFeedbackByOfferFeedbackId/${id}`,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formData = Object.assign({}, res.result);
            this.formData.offerFeedBackId = this.detailsData.offerFeedBackId;
            const arr = [];
            res.result.feedbackProductList.forEach((v) => {
              arr.push(Object.freeze(v));
            });
            this.getCustomerList(this.formData.customerName);
            this.offerData = Object.freeze(arr);
            this.status = true;
            this.getportArrList(this.formData.portArrival);
          } else {
            errorMessage(res.message);
          }
        },
      });
    },
    getOfferDetails() {
      // 新增获取报盘信息,构建还盘数据
      this.ajax({
        type: 'GET',
        url: `malicrm/offer/noAuth/offerDetail/${this.offerId}`,
        loading: true,
        success: (res) => {
          const data = this.formData;
          const resultData = res.result;
          data.categoryDesc = resultData.categoryName;
          data.countryDesc = resultData.countryName;
          data.plantNo = resultData.plantNo;
          data.supplierName = resultData.supplierName;
          data.incotermDesc = resultData.incotermName;
          data.supplierPaymentRatio = resultData.supplierPaymentRatio;
          data.tradeCurrencyDesc = resultData.tradeCurrencyName;
          data.tradeCurrency = resultData.tradeCurrency;
          data.exchangeRate = resultData.exchangeRate;
          const arr = [];
          resultData.productVoList.forEach((v) => {
            const obj = Object.assign(v, {
              // 这里要自定义重命名不同字段，不然显示有冲突
              offerFeedbackId: null,
              offerId: this.eventId,
              offerProductId: v.id,
              feedBackContainerQuantity: null,
              feedBackShippingDate: null,
              feedBackUnitPrice: null,
              feedBackWeightPerContainer: null,
              id: null,
            });
            arr.push(Object.freeze(obj));
          });
          this.offerData = Object.freeze(arr);
          this.status = true;
        },
      });
    },
    changeCateGory() {
      this.formData.country = null;
      this.formData.plantNo = [];
      this.getCountry();
      this.getPlantNo();
    },
    changeCountry() {
      this.formData.plantNo = [];
      this.getPlantNo();
    },
    getCountry() {
      // 获取国家信息
      this.ajax({
        type: 'POST',
        url: 'malicrm/approval/queryCountry',
        data: {
          category: this.formData.category,
          country: null,
          plantNo: null,
        },
        loading: true,
        success: (res) => {
          this.countryList = res.result;
        },
      });
    },
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 校验表格
          this.$refs.offerTable.$refs.tableForm.validate((valid2) => {
            if (valid2) {
              // 获取报盘产品信息
              const tableData = this.$refs.offerTable.getOfferTabeInfo();
              this.formData.agencyFee = tableData.agencyFee;
              const params = {
                customerId: this.formData.customerId,
                offerFeedBackId: this.formData.offerFeedBackId ? this.formData.offerFeedBackId : null,
                offerId: this.eventId,
                portArrival: this.formData.portArrival,
                productSaveDtoList: Object.assign([], tableData),
                remark: this.formData.remark,
                status: this.formData.status,
                version: this.formData.version,
              };

              const total = tableData.reduce((total, v) => {
                // 根据total数量判断至少填写了一个需求柜数即认为符合条件
                let containerQuantity = 0;
                if (v.containerQuantity === null || v.containerQuantity === '') {
                  containerQuantity = 0;
                } else {
                  containerQuantity = Number(v.containerQuantity);
                }
                return total + containerQuantity;
              }, 0);
              if (total > 0) {
                // 需求柜数至少大于0
                const containerQuantityData = tableData.filter((v) => v.containerQuantity > 0); // 筛选出填写了柜数的产品
                if (containerQuantityData.length > 0) {
                  const pluData = containerQuantityData.filter((v) => v.isSpellArk && v.seqIndex === 0); // 筛选出填写了柜数的非拼柜数据
                  const pluDataStatus = pluData.some((v) => v.weightPerContainer <= 0); // 判断单品是否填写了拼柜重量
                  if (pluDataStatus) {
                    this.errorTip('请输入需求的每柜吨数');
                  } else {
                    const repateData = containerQuantityData.filter((v) => v.isSpellArk === true && v.seqIndex > 0);
                    const repateArray = repateData.map((v) => v.containerSeq);
                    let woollyStatus = false;
                    let seq = 0;
                    for (let i = 0; i < repateArray.length; i++) {
                      const data = containerQuantityData.filter((v) => v.containerSeq === repateArray[i]);

                      const woollyTotal = data.reduce((total, v) => {
                        let weightPerContainer = 0;
                        if (v.weightPerContainer === null || v.weightPerContainer === '') {
                          weightPerContainer = 0;
                        } else {
                          weightPerContainer = Number(v.weightPerContainer);
                        }
                        return total + weightPerContainer;
                      }, 0);

                      if (woollyTotal <= 0) {
                        seq = repateArray[i];
                        woollyStatus = true;
                        break;
                      }
                    }
                    if (woollyStatus) {
                      this.errorTip(`柜子编号为${seq}的需求的每柜吨数必须大于0`);
                    } else {
                      this.submitForm(params);
                    }
                  }
                }
              } else {
                this.errorTip('请至少填写一个需求柜数');
              }
            } else {
              this.errorTip('请填写所有带星号的必填项');
            }
          });
        } else {
          this.errorTip('请填写所有带星号的必填项');
          return false;
        }
      });
    },
    submitForm(params) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/feedback/saveOfferFeedback',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.$emit('close', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    close() {
      // 取消操作
      this.$emit('close', false);
    },
    getCustomerList(value) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/selectCustomer',
        data: {
          customerName: value,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.options = res.result;
          }
        },
      });
    },
  },
  created() {
    if (this.formType === 1) {
      this.getportArrList(this.formData.portArrival);
      this.getCustomerList();
      this.getOfferDetails();
    } else {
      this.getFeedBackData(this.detailsData.offerFeedBackId);
    }
  },
};
</script>

<style></style>
