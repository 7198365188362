<template>
  <CrmFormLayout title="还盘详情">
    <div class="view__form">
      <div class="view__group">
        <label class="view__label">大类</label>
        <div class="view__input">{{ formData.categoryDesc }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">国家</label>
        <div class="view__input">{{ formData.countryDesc }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">厂号</label>
        <div class="view__input">{{ formData.plantNo }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">供 应 商</label>
        <div class="view__input">
          <el-tooltip
            effect="dark"
            :content="formData.supplierName"
            placement="top-start"
            :disabled="formData.supplierName && formData.supplierName.length <= 27"
            class="tootip-over"
          >
            <span>{{ formData.supplierName }}</span>
          </el-tooltip>
        </div>
      </div>
      <div class="view__group">
        <label class="view__label">Incoterm</label>
        <div class="view__input">{{ formData.incotermDesc }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">付款条件</label>
        <div class="view__input">{{ formData.supplierPaymentRatio }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">交易币种</label>
        <div class="view__input">{{ formData.tradeCurrencyDesc }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">当日汇率</label>
        <div class="view__input">{{ formData.exchangeRate }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">客户</label>
        <div class="view__input">
          <el-tooltip effect="dark" :content="formData.customerName" placement="top-start" :disabled="formData.customerName.length <= 27" class="tootip-over">
            <span>{{ formData.customerName }}</span>
          </el-tooltip>
        </div>
      </div>
      <div class="view__group">
        <label class="view__label">还盘状态</label>
        <div class="view__input">{{ formData.statusDesc }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">目的港</label>
        <div class="view__input">
          {{ formData.portArrival !== '' && formData.portArrival !== null ? formData.portArrival : '-' }}
        </div>
      </div>
    </div>

    <el-table :data="tableData" :span-method="objectSpanMethod" border class="view-table" v-if="status">
      <el-table-column align="center" prop="nameCn" label="中文品名" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="nameEn" label="英文品名" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="unitPrice" :label="'价格(' + formData.tradeCurrencyDesc + '/吨)'" width="80"></el-table-column>
      <el-table-column align="center" prop="containerQuantity" label="柜数" width="60"></el-table-column>
      <el-table-column align="center" prop="weightPerContainer" label="每柜吨数" width="60"></el-table-column>
      <el-table-column align="center" prop="shippingDate" label="船期" width="100"></el-table-column>
      <el-table-column align="center" prop="feedBackUnitPrice" :label="'还盘价格(' + formData.tradeCurrencyDesc + '/吨)'" width="100"></el-table-column>
      <el-table-column align="center" prop="feedBackContainerQuantity" label="需求柜数" width="60" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="feedBackWeightPerContainer" label="需求的每柜吨数" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="feedBackShippingDate" label="需求船期" width="110" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="isTransformed" label="是否已转商机" width="70">
        <template #default="scope">
          <span v-if="scope.row.isTransformed === true">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
    </el-table>
    <el-form>
      <div class="maili-edit__form label-con" style="margin-top: 20px" v-if="formData.remark !== ''">
        <el-form-item label="还盘备注" prop="remark">
          <el-input v-model="formData.remark" :maxlength="1000" clearable class="mali-full__input" type="textarea" resize="none" :readonly="true"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="crm-button__group">
      <button class="crm-form__button primary" @click="close">关闭</button>
    </div>
  </CrmFormLayout>
</template>

<script>
export default {
  components: {},
  props: ['detailsData'],
  created() {
    this.getFeedBackData(this.detailsData.offerFeedBackId);
  },
  data() {
    return {
      tableData: [
        {
          isSpellArk: true,
          seqIndex: 2,
          idIndex: 11,
          containerQuantity: '1-2',
          containerSeq: 1,
          identifier: '{"isSpellArk":true,"seqIndex":2,"idIndex":11}',
          labelId: null,
          nameCn: '1',
          nameEn: '1',
          productType: 1,
          shippingDate: '1201585',
          tariffComputeMode: null,
          tariffRate: null,
          unitPrice: '1',
          unitPriceRmb: '1',
          vatRate: null,
          weightPerContainer: '2',
        },
      ],
      formData: {
        category: '',
        categoryDesc: '',
        country: '',
        countryDesc: '',
        customerId: 0,
        customerName: '',
        exchangeRate: 0,
        feedbackProductList: [
          {
            containerQuantity: '',
            containerSeq: 0,
            feedBackContainerQuantity: 0,
            feedBackUnitPrice: 0,
            feedBackWeightPerContainer: 0,
            labelId: 0,
            nameCn: '',
            nameEn: '',
            offerFeedbackId: 0,
            offerId: 0,
            offerProductId: 0,
            shippingDate: '',
            shippingDateShippingDate: '',
            unitPrice: 0,
            version: 0,
            weightPerContainer: 0,
          },
        ],
        incoterm: '',
        incotermDesc: '',
        plantNo: '',
        portArrival: '',
        remark: '',
        status: '',
        statusDesc: '',
        supplierPaymentRatio: '',
        tradeCurrency: '',
        tradeCurrencyDesc: '',
      },
      colArrays: {},
      colNames: ['containerQuantity', 'shippingDate', 'feedBackShippingDate', 'isTransformed', 'feedBackContainerQuantity'],
      status: false,
    };
  },
  methods: {
    getFeedBackData(id) {
      // 获取还盘详情id
      this.ajax({
        type: 'GET',
        url: `malicrm/feedback/getOfferFeedbackActiveByOfferFeedbackId/${id}`,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formData = Object.assign({}, res.result);
            this.formData.offerFeedBackId = this.detailsData.offerFeedBackId;
            const arr = [];
            res.result.feedbackProductList.forEach((v) => {
              for (const key in v) {
                if (v[key] === '' || v[key] === null) {
                  v[key] = '-';
                }
              }
              arr.push(Object.freeze(v));
            });
            this.tableData = Object.freeze(arr);
            this.status = true;
            this.setColArrays(this.colNames);
            this.status = true;
          }
        },
      });
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.tableData.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (v[prop] === this.tableData[i - 1][prop] && v.containerSeq === this.tableData[i - 1].containerSeq) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },
    objectSpanMethod({ column, rowIndex, columnIndex }) {
      if (columnIndex === 3 || columnIndex === 5 || columnIndex === 7 || columnIndex === 9 || columnIndex === 10) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (this.colNames && this.colNames.length > 0) {
          this.colNames.forEach((v) => {
            if (v === prop) {
              const _row = this.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }
        return params;
      }
    },
    close() {
      this.$emit('close', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.view__form {
  padding: 24px 20px 4px;
  border: 1px solid #e3e4e6;
  background: #fff;
  overflow: hidden;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.maili-edit__form {
  width: 966px;
  overflow: hidden;
  background: #fff;
  padding: 16px 20px 6px 20px;
  border: 1px solid #e3e4e6;

  .el-form-item__label {
    color: #303133;
    margin-top: -4px;
    position: relative;
    padding-bottom: 0 !important;
    line-height: 30px !important;
  }

  .el-input--small .el-input__inner {
    height: 40px;
    line-height: 40px;
    font-size: 14px !important;
    padding: 0 10px;
    color: #1a1a1a;
    border-radius: 2px;
  }

  .mali-full__input11 .el-textarea__inner {
    height: 60px;
    word-break: break-all;
  }

  .el-textarea__inner {
    padding: 5px 10px;
    color: #1a1a1a;
    height: 140px;
    border-radius: 2px;
  }

  .mali-full__input {
    width: 100% !important;
  }

  .el-select .el-input .el-select__caret {
    color: #333;
    font-size: 12px;
    line-height: 40px;
  }

  .el-select .el-input .el-select__caret.el-icon-circle-close {
    font-size: 14px;
    color: #c0c4cc;
  }
}
</style>
