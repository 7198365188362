<template>
  <div>
    <!-- 查看详情 -->
    <div class="table-title">
      <div class="title__inner">
        <div style="font-size: 16px">还盘客户{{ index + 1 }}：</div>
        <el-tooltip
          :content="detailsData && detailsData.customerName"
          placement="top-start"
          :disabled="
            detailsData.customerName && detailsData.customerName.length < 40
          "
        >
          <div
            style="
              font-size: 16px;
              max-width: 680px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            {{ detailsData.customerName }}
          </div>
        </el-tooltip>
      </div>
      <span style="margin-left: 40px"
        >目的港：{{ empty(detailsData.portArrival) }}</span
      >
    </div>
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      border
      class="view-table"
      v-show="status"
      :show-summary="tableData && tableData.length > 1"
      :summary-method="getSummaries"
    >
      <el-table-column
        align="center"
        prop="nameCn"
        label="中文品名"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="nameEn"
        label="英文品名"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="weightPerContainer"
        label="每柜吨数"
        width="60"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="feedBackContainerQuantity"
        label="需求柜数"
        width="60"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="feedBackWeightPerContainer"
        label="需求的每柜吨数"
        width="80"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="reqWeight"
        label="需求吨数"
        width="60"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="feedBackUnitPrice"
        :label="'还盘价格(' + detailsData.tradeCurrencyDesc + '/吨)'"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="feedBackShippingDate"
        label="需求船期"
        width="110"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="isTransformed"
        label="是否已转商机"
        width="90"
      >
        <template #default="scope">
          <span v-if="scope.row.isTransformed === true">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="ele-declaration table-mark" v-if="detailsData.remark !== ''">
      <pre class="show-remark">还盘备注: {{ detailsData.remark }}</pre>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: [String, Number],
    },
    detailsData: {
      type: [Object],
    },
  },
  data() {
    return {
      tableData: [],
      formData: {},
      colArrays: {},
      colNames: ['isTransformed', 'feedBackContainerQuantity'],
      status: false,
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    empty(data) {
      if (data !== null && data !== '') {
        return data;
      } else {
        return '-';
      }
    },
    getTableData() {
      const arr = [];
      this.detailsData.feedbackProductList.forEach((v) => {
        for (const key in v) {
          if (v[key] === '' || v[key] === null) {
            v[key] = '-';
          }
        }
        arr.push(Object.freeze(v));
      });
      this.tableData = arr;
      this.setColArrays(this.colNames);
      this.status = true;
    },
    getSummaries() {
      let totalQuantity = '-';
      if (this.detailsData && this.detailsData.totalQuantity) {
        totalQuantity = this.detailsData.totalQuantity;
      }
      let totalWeight = '-';
      if (this.detailsData && this.detailsData.totalWeight) {
        totalWeight = this.detailsData.totalWeight;
      }
      if (this.tableData.length > 1) {
        return [
          '合计',
          '-',
          '-',
          totalQuantity,
          '-',
          totalWeight,
          '-',
          '-',
          '-',
        ];
      } else {
        return ['', '', '', '', '', '', '', '', ''];
      }
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.tableData.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (
              v[prop] === this.tableData[i - 1][prop] &&
              v.containerSeq === this.tableData[i - 1].containerSeq
            ) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },
    objectSpanMethod({ column, rowIndex, columnIndex }) {
      if (columnIndex === 3 || columnIndex === 8) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (this.colNames && this.colNames.length > 0) {
          this.colNames.forEach((v) => {
            if (v === prop) {
              const _row = this.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }

        return params;
      }
    },
    submit() {
      this.$emit('cancel', true);
    },
  },
};
</script>

<style scoped>
.ele-declaration.table-mark .show-remark {
  margin-top: 20px;
  font-size: 14px;
  padding: 15px 19px;
}
.table-title {
  margin-top: 19px;
  margin-bottom: 11px;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
}
.table-title > * {
  font-size: 16px;
  color: #333;
}
.title__inner {
  display: flex;
  align-items: center;
}
.el-table__footer-wrapper tbody td {
  background: #fff;
}
</style>
