<template>
  <CrmFormLayout :title="'自动匹配客户'" @close="cancel">
    <div class="auto-match-page">
      <h2 style="font-size: 16px; color: #333; margin-bottom: 11px; font-weight: 400">以下是对本报盘感兴趣的客户名单以及他们对产品的需求</h2>
      <el-checkbox-group v-model="checkData">
        <table class="productCate__table" cellspacing="0" cellpadding="0">
          <tr>
            <th class style="width: 64px">
              <span class></span>
            </th>
            <th style="width: 220px">
              <span class>客户</span>
            </th>
            <th style="width: 78px">
              <span>大类</span>
            </th>
            <th style="width: 125px">
              <span>国家</span>
            </th>
            <th>
              <span>供应商类型</span>
            </th>
            <th style="width: 210px">
              <span>品名关键词</span>
            </th>
            <th style="width: 78px">
              <span>备注</span>
            </th>
            <th style="width: 90px">
              <span>报盘状态</span>
            </th>
          </tr>
          <template v-if="data.length > 0">
            <tr v-for="(item, index) in data" :key="index">
              <td class="port__inner" style="width: 60px">
                <el-checkbox :label="item.customerId" v-if="item.offerStatus == 0" style="position: relative; left: 7px">&nbsp;</el-checkbox>
              </td>
              <td class="port__inner" style="width: 200px">
                <el-tooltip :content="item.customerName" placement="top-start" :disabled="item.customerName && item.customerName.length < 14">
                  <div style="width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; word-break: break-all">
                    {{ empty(item.customerName) }}
                  </div>
                </el-tooltip>
              </td>
              <td class="port__inner" style="width: 80px; color: #333">
                <div>{{ empty(item.categoryName) }}</div>
              </td>
              <td class="port__inner" style="width: 125px">
                <el-tooltip
                  :content="item.countryName"
                  placement="top-start"
                  :disabled="item.countryName == null || (item.countryName && item.countryName.length < 8)"
                >
                  <div style="width: 104px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; word-break: break-all">
                    {{ empty(item.countryName) }}
                  </div>
                </el-tooltip>
              </td>
              <td class="port__inner">
                <el-tooltip
                  :content="item.supplierCompanyTypeName"
                  placement="top-start"
                  :disabled="item.supplierCompanyTypeName == null || (item.supplierCompanyTypeName && item.supplierCompanyTypeName.length < 5)"
                >
                  <div style="width: 104px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; word-break: break-all">
                    {{ empty(item.supplierCompanyTypeName) }}
                  </div>
                </el-tooltip>
              </td>
              <td class="port__inner">
                <el-tooltip :content="item.productNameKeyword" placement="top-start" :disabled="item.productNameKeyword && item.productNameKeyword.length < 25">
                  <div style="width: 189px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; word-break: break-all">
                    {{ empty(item.productNameKeyword) }}
                  </div>
                </el-tooltip>
              </td>
              <td class="port__inner" style="width: 78px">
                <span class="record__link" @click="showRemark(item.remark)" v-if="item.remark !== '' && item.remark != null">查看</span>
                <div v-else>-</div>
              </td>
              <td class="port__inner" style="width: 90px">
                <div>{{ empty(item.offerStatusName) }}</div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="8">
                <div style="text-align: center; padding: 26px; color: #999; font-size: 16px">暂无数据</div>
              </td>
            </tr>
          </template>
        </table>
        <div class="align-right" v-show="isShow" style="margin-top: 10px">
          <Pagination url="/malicrm/offer/matchCustomer" :queryData="queryData" @change="getTableDatas" class="dialog-customer-pagination" ref="paginationRef">
          </Pagination>
        </div>
      </el-checkbox-group>
      <div class="crm-button__group" v-if="data.length > 0">
        <button class="crm-form__button primary" @click="submitForm">标记已报盘</button>
        <button class="crm-form__button cancel" @click="cancel">关闭</button>
      </div>
    </div>
  </CrmFormLayout>
  <el-dialog
    title="查看备注"
    v-model="visible"
    :append-to-body="true"
    @close="visible = false"
    width="560px"
    top="30vh"
    custom-class="auto-match-remark-dialog"
  >
    <div style="line-height: 1.5">
      {{ remark }}
    </div>
  </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue';
export default {
  components: {
    Pagination,
  },
  props: {
    offerId: {
      type: [String, Number],
      default: '',
    },
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看 查看没有操作按钮
      type: [String, Number],
      default: 1,
    },
    source: {
      // 任务类型(来源)
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isShow: false,
      queryData: {
        offerId: this.offerId,
        pageSize: 15,
        pageNum: 1,
      },
      pageUrl: '/malicrm/offer/matchCustomer',
      visible: false,
      remark: '',
      checkData: [],
      data: [],
    };
  },
  methods: {
    empty(val) {
      if (val !== null && val !== '') {
        return val;
      } else {
        return '-';
      }
    },
    getTableDatas(res, total) {
      if (Number(total) > 15) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.data = res;
    },
    showRemark(remark) {
      this.remark = remark;
      this.visible = true;
    },
    submit() {
      // 提交操作
      this.submitForm();
    },
    submitForm() {
      // 提交表单
      const customer = this.checkData.filter((v) => v !== null);
      if (customer.length > 0) {
        const params = {
          customerIds: customer,
          offerId: this.offerId,
        };
        this.ajax({
          type: 'POST',
          url: '/malicrm/offer/customerMarkOffer',
          data: {
            ...params,
          },
          loading: true,
          success: (res) => {
            if (res.code === 200) {
              this.successTip('标记成功', 2000);
              this.$emit('close', true);
            } else {
              this.errorTip(res.message);
            }
          },
        });
      } else {
        this.errorTip('请选择客户');
      }
    },
    cancel() {
      // 取消操作
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped lang="scss">
.auto-match-page {
  font-size: 14px;
}
.productCate__table {
  width: 100%;
  background: #fff;
  border-right: 1px solid #e3e4e6;
  border-bottom: 1px solid #e3e4e6;
  box-sizing: border-box;

  .center {
    text-align: center;
  }

  th {
    border-left: 1px solid #e3e4e6;
    border-top: 1px solid #e3e4e6;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #333;
    vertical-align: middle;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #333;
    }
    .required {
      &::after {
        content: '*';
        display: inline-block;
        position: relative;
        color: #ff6c2b;
        font-size: 24px;
        vertical-align: middle;
        top: 3px;
        left: 7px;
      }
    }
  }

  td {
    border-left: 1px solid #e3e4e6;
    border-top: 1px solid #e3e4e6;
    padding: 6px;
    color: #333;
    div {
      font-size: 14px;
      font-weight: 400;
      color: #333;
    }
    &.port__inner {
      padding: 12px 10px;
      text-align: center;
      word-wrap: break-word;
      line-height: 1.25;
    }

    .text-overflow {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-input__inner {
      color: #1a1a1a;
      padding: 0 10px;
      font-size: 14px;
    }
  }

  .from__Detailed_locn {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .th-48 {
    width: 48px;
  }

  .th-120 {
    width: 120px;
  }

  .th-237 {
    width: 237px;
  }

  .th-320 {
    width: 320px;
  }

  .th-140 {
    width: 140px;
  }

  .th-160 {
    width: 160px;
  }

  .th-200 {
    width: 200px;
  }

  .opt {
    cursor: pointer;
    text-align: center;

    [class^='el-icon-'] {
      font-size: 20px;
    }
  }
}
</style>
