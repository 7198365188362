<template>
  <el-form :model="data" class="mt-20 view-counter-offer-table" ref="tableForm">
    <table class="productCate__table" cellspacing="0" cellpadding="0">
      <tr class="offertable__th">
        <th style="width: 75px">
          <div class>柜子编号</div>
        </th>
        <th style="width: 103px">
          <div class>中文品名</div>
        </th>
        <th style="width: 120px">
          <div class>英文品名</div>
        </th>
        <th style="width: 112px">
          <div class>价格({{ tradeCurrencyName }}/吨)</div>
        </th>
        <th style="width: 70px">
          <div class>柜数</div>
        </th>
        <th style="width: 70px">
          <div>每柜吨数</div>
        </th>
        <th style="width: 90px">
          <div class>船期</div>
        </th>
        <th style="width: 130px">
          <div class>还盘价格({{ tradeCurrencyName }}/吨)</div>
        </th>
        <th style="width: 80px">
          <div class="req">需求柜数</div>
        </th>
        <th style="width: 145px">
          <div class="req">
            需求的每
            <br />柜吨数
          </div>
        </th>
        <th style="width: 100px">
          <div class>需求船期</div>
        </th>
      </tr>
      <tbody>
        <tr v-for="(item, index) in data.productDtos" :key="index">
          <td style="width: 70px">{{ item.containerSeq }}</td>
          <td>
            <el-tooltip
              :content="item.nameCn"
              placement="top-start"
              class="text-cn"
              :disabled="item.nameCn.length <= 8"
            >
              <div>{{ item.nameCn }}</div>
            </el-tooltip>
          </td>
          <td style="width: 150px">
            <el-tooltip
              :content="item.nameEn"
              placement="top-start"
              class="text-en"
              :disabled="item.nameCn.length < 10"
            >
              <div>{{ item.nameEn }}</div>
            </el-tooltip>
          </td>
          <td>{{ item.unitPrice }}</td>
          <td>
            <span v-if="item.productType == 2 || item.isSpellArk">{{
              item.containerQuantity
            }}</span>
            <span v-else>-</span>
          </td>
          <td>
            <span
              v-if="
                item.weightPerContainer !== null &&
                item.weightPerContainer !== ''
              "
              >{{ item.weightPerContainer }}</span
            >
            <span v-else>-</span>
          </td>
          <td>
            <el-tooltip
              v-if="item.productType == 2 || item.isSpellArk"
              :content="item.shippingDate"
              placement="top-start"
              class="text-cn"
              :disabled="item.shippingDate.length <= 11"
              style="width: 75px"
            >
              <div>{{ item.shippingDate }}</div>
            </el-tooltip>
            <span v-else>-</span>
          </td>
          <td>
            <el-input
              v-model="item.feedBackUnitPrice"
              :disabled="item.isTransformed === true"
              :maxlength="8"
              @blur="changeUnitPrice($event, item)"
            ></el-input>
          </td>
          <td>
            <el-form-item
              label
              :prop="'productDtos.' + index + '.feedBackContainerQuantity'"
            >
              <el-input
                v-model="item.feedBackContainerQuantity"
                :maxlength="3"
                v-if="item.productType == 2 || item.isSpellArk"
                :disabled="item.isTransformed === true"
                @blur="
                  changeFeedBackContainerQuantity(
                    item,
                    'feedBackContainerQuantity',
                    $event
                  )
                "
              ></el-input>
              <span v-else>-</span>
            </el-form-item>
          </td>
          <td>
            <el-form-item
              label
              :prop="'productDtos.' + index + '.feedBackWeightPerContainer'"
            >
              <el-input
                :disabled="item.isTransformed === true"
                v-model="item.feedBackWeightPerContainer"
                :maxlength="9"
                @blur="changeFeedBackWeightPerContainer($event, item)"
              ></el-input>
            </el-form-item>
          </td>
          <td>
            <!-- 还盘船期 -->
            <el-input
              v-if="item.productType == 2 || item.isSpellArk"
              :maxlength="15"
              v-model="item.feedBackShippingDate"
              style="width: 90px"
              :disabled="item.isTransformed === true"
              @blur="
                changeFeedBackContainerQuantity(
                  item,
                  'feedBackShippingDate',
                  $event
                )
              "
            ></el-input>
            <span v-else>-</span>
          </td>
        </tr>
      </tbody>
    </table>
  </el-form>
</template>
<script>
export default {
  props: {
    tradeCurrency: {
      // 大类
      type: [String, Number],
      default: '',
    },
    offerData: {
      // 表格信息
      type: [Object, Array],
      default: function () {
        return [];
      },
    },
  },
  watch: {},
  computed: {
    tradeCurrencyName() {
      const arr = ['', '美元', '澳元', '欧元'];
      return arr[this.tradeCurrency];
    },
  },
  data() {
    return {
      rate: {
        show: false,
        vatRate: null,
        tariffRate: null,
        tariffComputeMode: null,
      },
      containerSeq: 1, // 初始柜号
      data: {
        productDtos: [],
      },
    };
  },
  created() {
    const formatData = JSON.parse(JSON.stringify(this.offerData));
    formatData.forEach((v) => {
      const customObj = JSON.parse(v.identifier);
      v = Object.assign(v, customObj);
      // 处理下拼柜重量
      if (v.offerFeedbackId === null) {
        // 编辑时不需要处理柜数重量
        // 单柜的重量设置为报盘重量
        if (v.isSpellArk === true && v.seqIndex === 0) {
          v.feedBackWeightPerContainer = v.weightPerContainer;
        }
      }
    });
    this.data.productDtos = formatData;
  },
  methods: {
    changeFeedBackContainerQuantity(item, name, e) {
      const data = this.data.productDtos;
      let value = e.target.value;
      if (name === 'feedBackContainerQuantity') {
        value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符
        if (value > 200) {
          value = value.substr(0, 2);
        }
        item[name] = value;
      } else {
        value = value.replace(/[a-zA-Z\u4E00-\u9FA5]/g, ''); // 清除中文英文以外的字符
        item[name] = value;
      }

      data.forEach((v) => {
        // 更新拼柜数据
        if (v.spellArkId === item.idIndex) {
          v.feedBackContainerQuantity = item.feedBackContainerQuantity;
          v.feedBackShippingDate = item.feedBackShippingDate;
        } else {
          return false;
        }
      });
    },
    getOfferTabeInfo() {
      // 获取处理完自定义参数后的数据,统一从该方法出去
      const data = JSON.parse(JSON.stringify(this.data.productDtos));
      const arr = [];
      data.forEach((v) => {
        const obj = {
          containerSeq: v.containerSeq,
          isSpellArk: v.isSpellArk,
          seqIndex: v.seqIndex,
          id: v.id,
          containerQuantity: v.feedBackContainerQuantity,
          offerFeedbackId: v.offerFeedbackId,
          offerProductId: v.offerProductId,
          shippingDate: v.feedBackShippingDate,
          unitPrice: v.feedBackUnitPrice,
          weightPerContainer:
            v.feedBackWeightPerContainer === ''
              ? null
              : v.feedBackWeightPerContainer,
        };
        arr.push(obj);
      });

      return arr;
    },
    changeFeedBackWeightPerContainer(e, item) {
      const value = this.formatPrice(e.target.value);
      item.feedBackWeightPerContainer = value;
    },
    changeUnitPrice(e, item) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value !== '' && !isNaN(Number(value))) {
        value = Number(value);
      }

      item.feedBackUnitPrice = value;
    },
    formatPrice(value) {
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d\d\d\d\d).*$/, "$1$2.$3"); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (value !== '' && !isNaN(Number(value))) {
        value = Number(value);
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.productCate__table {
  width: 100%;
  background: #fff;
  border-right: 1px solid #e3e4e6;
  border-bottom: 1px solid #e3e4e6;
  box-sizing: border-box;
  margin-top: 20px;
  .center {
    text-align: center;
  }

  th {
    border-left: 1px solid #e3e4e6;
    border-top: 1px solid #e3e4e6;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #333;
    vertical-align: middle;

    .required {
      &::after {
        content: '*';
        display: inline-block;
        position: relative;
        color: #ff6c2b;
        font-size: 24px;
        vertical-align: middle;
        top: 3px;
        left: 7px;
      }
    }
  }

  td {
    border-left: 1px solid #e3e4e6;
    border-top: 1px solid #e3e4e6;
    padding: 6px;
    color: #333;

    &.port__inner {
      padding: 12px 10px;
      text-align: center;
      word-wrap: break-word;
      line-height: 1.25;
    }

    .text-overflow {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-input__inner {
      color: #1a1a1a;
      padding: 0 10px;
      font-size: 14px;
    }
  }

  .from__Detailed_locn {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .th-48 {
    width: 48px;
  }

  .th-120 {
    width: 120px;
  }

  .th-237 {
    width: 237px;
  }

  .th-320 {
    width: 320px;
  }

  .th-140 {
    width: 140px;
  }

  .th-160 {
    width: 160px;
  }

  .th-200 {
    width: 200px;
  }

  .opt {
    cursor: pointer;
    text-align: center;

    [class^='el-icon-'] {
      font-size: 20px;
    }
  }
}
.text-cn {
  width: 100px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  line-height: 1.5;
  white-space: nowrap;
}
.text-en {
  width: 155px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  line-height: 1.5;
  white-space: nowrap;
}
.offertable__flex {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.offertable__agencyFee {
  display: flex;
  align-items: center;
}
.offertable__label {
  font-size: 16px;
  margin-right: 10px;
}
.offertable__th th {
  line-height: 1.2;
  padding: 0 4px;
  position: relative;
  .req {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.productCate__table .el-icon-circle-plus-outline {
  font-size: 24px;
  cursor: pointer;
}
.productCate__table .el-icon-remove-outline {
  font-size: 24px;
  cursor: pointer;
}

.productCate__table th .req::after {
  display: flex;
  align-items: center;
  content: '*';
  font-size: 24px;
  color: #ff6c2b;

  position: relative;
  left: 3px;
  top: 5px;
}
.productCate__table td {
  text-align: center;
  vertical-align: middle;
}
</style>
