<template>
  <el-select
    v-model="chooseValue"
    :filter-method="getData"
    @blur="changeCustomer"
    filterable
    remote
    :popper-append-to-body="false"
    style="width: 100%"
    placeholder=" "
    @change="getValue"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.customerName"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  props: ['item', 'value'],
  data() {
    return {
      chooseValue: '',
      options: [],
      data: {
        createdTime: '',
        creator: '',
        customerName: '',
        id: 0,
      },
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.chooseValue = newVal;
      },
    },
  },
  created() {
    this.getData('');
  },
  methods: {
    changeCustomer() {
      if (this.options.length <= 0) {
        this.getData('');
      }
    },
    getValue(value) {
      this.$emit('input', value);
    },
    getData(value) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/business/selectCustomer',
        data: {
          customerName: value,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.options = res.result;
            }
          }
        },
      });
    },
  },
};
</script>

<style></style>
