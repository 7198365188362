<template>
  <CrmFormLayout title="还盘汇总">
    <div class="view-rise-page">
      <div class="view__form">
        <div class="view__group">
          <label class="view__label">报盘编号</label>
          <div class="view__input">{{ formData.offerNo }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">报盘时间</label>
          <div class="view__input">{{ formData.auditTime }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">大类</label>
          <div class="view__input">{{ formData.categoryDesc }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">国家</label>
          <div class="view__input">{{ formData.countryDesc }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">厂号</label>
          <div class="view__input">{{ formData.plantNo }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">供 应 商</label>
          <div class="view__input">
            <el-tooltip
              effect="dark"
              :content="formData.supplierName"
              placement="top-start"
              :disabled="
                formData.supplierName && formData.supplierName.length <= 27
              "
              class="tootip-over"
            >
              <span>{{ formData.supplierName }}</span>
            </el-tooltip>
          </div>
        </div>
        <div class="view__group">
          <label class="view__label">Incoterm</label>
          <div class="view__input">{{ formData.incotermDesc }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">付款条件</label>
          <div class="view__input">{{ formData.supplierPaymentRatio }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">交易币种</label>
          <div class="view__input">{{ formData.tradeCurrencyDesc }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">当日汇率</label>
          <div class="view__input">{{ formData.exchangeRate }}</div>
        </div>
      </div>

      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        class="view-table counter-summary"
        v-if="status"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          align="center"
          prop="nameCn"
          label="中文品名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="nameEn"
          label="英文品名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="unitPrice"
          :label="'价格(' + formData.tradeCurrencyDesc + '/吨)'"
          width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="containerQuantity"
          label="柜数"
          width="60"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="weightPerContainer"
          label="每柜吨数"
          width="60"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="shippingDate"
          label="船期"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="totalQuantity"
          label="合计需求柜数"
          width="70"
          label-class-name="red"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="totalWeight"
          label="合计需求吨数"
          width="70"
          label-class-name="red"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <template v-if="status">
        <OfferTable
          v-for="(item, index) in formData.detailList"
          :key="item.offerFeedbackId"
          :index="index"
          :detailsData="item"
        ></OfferTable>
      </template>
      <div class="crm-button__group">
        <button class="crm-form__button primary" @click="close(false)">
          关闭
        </button>
      </div>
    </div>
  </CrmFormLayout>
</template>

<script>
import OfferTable from './OfferTable.vue';
export default {
  components: { OfferTable },
  props: ['offerId'],
  created() {
    this.getinitData(this.offerId);
  },
  data() {
    return {
      tableData: [],
      formData: {},
      colArrays: {},
      colNames: [
        'containerQuantity',
        'shippingDate',
        'totalWeight',
        'totalQuantity',
      ],
      status: false,
    };
  },
  methods: {
    getSummaries() {
      return [
        '合计',
        '-',
        '-',
        '-',
        '-',
        '-',
        this.formData.gatherVo.allTotalQuantity,
        this.formData.gatherVo.allTotalWeight,
      ];
    },
    getinitData(id) {
      this.ajax({
        type: 'GET',
        url: `malicrm/feedback/getOfferFeedbackGatherByOfferId/${id}`,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.formData = Object.assign({}, res.result);
            const arr = [];
            res.result.gatherVo.list.forEach((v) => {
              for (const key in v) {
                if (v[key] === '' || v[key] === null) {
                  v[key] = '-';
                }
              }
              arr.push(Object.freeze(v));
            });
            this.tableData = Object.freeze(arr);
            this.setColArrays(this.colNames);
            this.status = true;
          }
        },
      });
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.tableData.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (
              v[prop] === this.tableData[i - 1][prop] &&
              v.containerSeq === this.tableData[i - 1].containerSeq
            ) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },
    objectSpanMethod({ column, rowIndex, columnIndex }) {
      if (columnIndex === 3 || columnIndex === 5 || columnIndex === 6) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (this.colNames && this.colNames.length > 0) {
          this.colNames.forEach((v) => {
            if (v === prop) {
              const _row = this.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }
        return params;
      }
    },
    close() {
      this.$emit('close', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.view__form {
  padding: 24px 20px 4px;
  border: 1px solid #e3e4e6;
  background: #fff;
  overflow: hidden;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>
