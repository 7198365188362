
import { defineComponent, reactive, ref, toRefs } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import usePagination from '@/hooks/usePagination';
import { erpConfirm, errorMessage, setTitleNum, successMessage, hasPermission, loadPage } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';
import CounterSummary from '../CounterSummary/index.vue';
import ViewForm from './ViewForm.vue';
import CounterForm from './CounterForm.vue';
// import TransformBusiness from '../TransformBusiness/TransformBusiness.vue';
import { httpPost } from '@/api';
export default defineComponent({
  /** data 客户或 商机信息 */
  props: ['eventId', 'tradeCurrency'],
  components: {
    Pagination,
    CounterSummary,
    ViewForm,
    CounterForm,
    // TransformBusiness,
  },
  emits: ['getSize'],
  setup(props, ctx) {
    const queryData = {
      offerId: props.eventId,
      pageNum: 1,
      pageSize: 5,
    };
    const { paginationRef, tableData } = usePagination();
    const formType = ref(1); // 1新建还盘  2编辑还盘 3还盘详情  4转商机  5还盘汇总
    const rowData = ref();
    const customData = reactive({
      isShowPagination: true,
      totalWeight: '',
      totalQuantity: '',
    });

    const { visible } = useVisible();

    const refashTable = () => {
      paginationRef.value.refashTable();
      visible.value = false;
    };
    // 获取表格数据操作
    const getTableDatas = (data: Array<any>, total: number, resObj: any) => {
      // 分页查询
      if (total <= 5) {
        customData.isShowPagination = false;
      } else {
        customData.isShowPagination = true;
      }
      customData.totalWeight = resObj.totalWeight;
      customData.totalQuantity = resObj.totalQuantity;
      if (data) {
        data.forEach((item) => {
          for (const key in item) {
            if (item[key] === '' || item[key] === null) {
              item[key] = '-';
            }
          }
        });
        tableData.value = data;
      }
      ctx.emit('getSize', setTitleNum('还盘记录', total));
    };
    // 新增编辑操作
    const operateDrawer = (type, row) => {
      if (row) {
        rowData.value = row;
      }
      formType.value = type;
      visible.value = true;
    };
    // 表单关闭操作，重新触发数据更新
    const closeVisible = () => {
      console.log('closeVisible');
      visible.value = false;
      refashTable();
    };
    const toCustomerPage = (row) => {
      if (row.hasPermission) {
        loadPage('ContactDetails', {
          id: row.customerId,
          type: 1,
          isRecycle: false,
          backFrom: '/offer/offerDetails',
        });
      } else {
        errorMessage('您不是该客户的负责人或负责人上级');
      }
    };
    const closeBusinessDialog = (res) => {
      closeVisible();
      if (res) {
        refashTable();
      }
    };
    const delCounter = (row) => {
      erpConfirm('确定要删除该报盘记录吗？删除后无法还原。').then(async () => {
        const url = 'malicrm/feedback/deleteOfferFeedback';
        const res = await httpPost(url, {
          offerFeedbackId: row.offerFeedBackId,
          version: row.version,
        });
        if (res.code === 200) {
          successMessage('已删除');
          refashTable();
        } else {
          errorMessage(res.message);
        }
      });
    };
    return {
      delCounter,
      tableData,
      queryData,
      getTableDatas,
      operateDrawer,
      closeVisible,
      closeBusinessDialog,
      visible,
      formType,
      paginationRef,
      hasPermission,
      rowData,
      toCustomerPage,
      ...toRefs(customData),
    };
  },
});
